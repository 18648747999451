<template>
  <Layout>
    <PageHeader title="New Survey" />
    <form-wizard
      shape="tab"
      color="#5664d2"
      class="set-survey"
      @on-complete="createSurvey"
      :finish-button-text="computeButtonNames"
    >
      <tab-content icon="mdi mdi-clipboard-outline">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="text">Survey Title</label>
                  <input
                    type="text"
                    for="text"
                    class="form-control"
                    placeholder="Survey Name"
                    v-model="newSurvey.name"
                  />
                </div>
                <div class="form-group">
                  <label for="text">Survey Description</label>
                  <textarea
                    type="text"
                    for="text"
                    class="form-control"
                    placeholder="Survey Description"
                    v-model="newSurvey.desc"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="d-block" for="event-select"
                    >Relational Event</label
                  >
                  <small
                    >If this survey belongs to an event, please select.</small
                  >
                  <select
                    id="event-select"
                    class="form-control"
                    v-model="newSurvey.eventId"
                  >
                    <option value="null">Please select</option>
                    <option
                      v-for="(event, index) in events"
                      :value="event.id"
                      :key="index"
                    >
                      {{ event.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-8">
            <div
              class="card mb-2"
              v-for="(survey, index) in newSurvey.questions"
              :key="index"
            >
              <div class="card-body">
                <div class="form-group">
                  <div class="row">
                    <div class="col">{{ index + 1 }}. Question</div>
                    <div class="col text-right">
                      <b>Question Type:</b>
                      <select
                        id="question-type-select"
                        class="mb-2"
                        v-model="survey.type"
                      >
                        <option value="null">Please Select</option>
                        <option value="1">Choise</option>
                        <option value="2">Rich Text</option>
                        <option value="3">Order</option>
                      </select>
                    </div>
                  </div>

                  <input
                    type="text"
                    for="text"
                    class="form-control"
                    placeholder="Please type your question"
                    v-model="newSurvey.questions[index].question"
                  />
                </div>

                <!-- Question Type 1 -->
                <div
                  class="form-group define-option"
                  v-if="newSurvey.questions[index].type == 1"
                >
                  <div
                    v-for="(option, optionIndex) in newSurvey.questions[index]
                      .options"
                    :key="optionIndex"
                  >
                    <div class="option-wrapper mt-2">
                      <input
                        type="radio"
                        disabled="disabled"
                        class="form-check-input"
                      />
                      <input
                        type="text"
                        for="text"
                        class="form-control"
                        placeholder="Please type your option"
                        v-model="
                          newSurvey.questions[index].options[optionIndex]
                        "
                      />
                      <button
                        class="btn btn-rounded delete-option"
                        @click="deleteOption(index, optionIndex)"
                      >
                        <i class="ri-delete-bin-5-fill"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 text-center">
                      <button
                        class="btn btn-light btn-rounded add-new-option"
                        @click="addNewOption(index)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Question Type 1 End -->
                <!-- Question Type 2 -->
                <div
                  class="form-group define-option"
                  v-if="newSurvey.questions[index].type == 2"
                >
                  <div
                    role="alert"
                    aria-live="polite"
                    aria-atomic="true"
                    class="alert alert-secondary"
                  >
                    Users will see a standard textbox for this question answer.
                  </div>
                </div>
                <!-- Question Type 2 End-->
                <!-- Question Type  -->
                <div
                  class="form-group define-option"
                  v-if="newSurvey.questions[index].type == 3"
                >
                  <div
                    role="alert"
                    aria-live="polite"
                    aria-atomic="true"
                    class="alert alert-info"
                  >
                    Users will see a sortable list of options for this question
                    answer.
                  </div>
                  <div
                    v-for="(option, optionIndex) in newSurvey.questions[index]
                      .options"
                    :key="optionIndex"
                  >
                    <div class="option-wrapper mt-2">
                      <input
                        type="text"
                        for="text"
                        class="form-control"
                        placeholder="Please type your option"
                        v-model="
                          newSurvey.questions[index].options[optionIndex]
                        "
                      />
                      <button
                        class="btn btn-rounded delete-option"
                        @click="deleteOption(index, optionIndex)"
                      >
                        <i class="ri-delete-bin-5-fill"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 text-center">
                      <button
                        class="btn btn-light btn-rounded add-new-option"
                        @click="addNewOption(index)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Question Type 1 End -->
              </div>
            </div>

            <!-- Add New Question -->
            <div class="row mt-4">
              <div class="col-12 text-center">
                <button
                  class="btn btn-light btn-rounded add-new-question"
                  @click="addNewQuestion()"
                >
                  +
                </button>
              </div>
            </div>
            <!-- Add New Question -->
          </div>
        </div>
      </tab-content>
      <tab-content icon="mdi mdi-content-save">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="card">
              <div class="card-body">
                <span class="h6 d-block mb-3">Survey Preview</span>
                <!--  -->
                <div
                  v-for="(survey, index) in newSurvey.questions"
                  :key="index"
                >
                  <strong class="d-block mt-2 mb-1">
                    {{ index + 1 }}. {{ newSurvey.questions[index].question }}
                  </strong>
                  <div class="d-flex">
                    <div class="col">
                      <!-- Type 1 -->
                      <div
                        class="form-group"
                        v-if="newSurvey.questions[index].type == 1"
                      >
                        <div
                          v-for="(option, optionIndex) in newSurvey.questions[
                            index
                          ].options"
                          :key="optionIndex"
                        >
                          <div class="preview-option-wrapper">
                            <input type="radio" class="form-check-input" />
                            {{
                              newSurvey.questions[index].options[optionIndex]
                            }}
                          </div>
                        </div>
                      </div>
                      <!-- Type 1 end -->
                      <!-- Type 2 -->
                      <div v-if="newSurvey.questions[index].type == 2">
                        <div
                          role="alert"
                          aria-live="polite"
                          aria-atomic="true"
                          class="alert alert-secondary"
                        >
                          Users will see a standard textbox for this question
                          answer.
                        </div>
                      </div>
                      <!-- Type 2 end -->
                      <!-- Type 2 -->
                      <div v-if="newSurvey.questions[index].type == 3">
                        <div
                          role="alert"
                          aria-live="polite"
                          aria-atomic="true"
                          class="alert alert-info"
                        >
                          Users will see a sortable list of options for this
                          question answer.
                        </div>
                        <div
                          v-for="(option, optionIndex) in newSurvey.questions[
                            index
                          ].options"
                          :key="optionIndex"
                        >
                          <div class="preview-option-wrapper">
                            <i class="ri-list-unordered"></i>
                            {{
                              newSurvey.questions[index].options[optionIndex]
                            }}
                          </div>
                        </div>
                      </div>
                      <!-- Type 3 end-->
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card">
              <div class="card-body">
                <span class="h6 d-block mb-3">Investors</span>
                <vue-good-table
                  :columns="columns"
                  :rows="investors"
                  ref="investorLists"
                  @on-selected-rows-change="setSelectedCount"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: false,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true,
                    selectAllByGroup: true,
                  }"
                >
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
    </form-wizard>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { HTTP } from "@/main-source";
import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
import router from "@/router";

export default {
  name: "new-survey",
  data() {
    return {
      events: [],
      firms: [],
      newSurvey: {
        name: null,
        desc: null,
        eventId: null,
        selectedInvestors: null,
        questions: [
          {
            type: 1,
            question: null,
            options: [""],
          },
        ],
      },
      investors: [],
      selectedInvestorsCount: 0,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Fon",
          field: "fon",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
  },
  created() {
    this.getEvents();
    this.getInvestorsAndFirms();
    if (this.$route.name == "survey-edit") {
      this.getSurveyDetail();
    }
  },
  computed: {
    computeButtonNames() {
      let buttonText =
        this.$route.name == "survey-edit" ? "Update Survey " : "Create Survey";
      if (this.selectedInvestorsCount > 0) {
        buttonText += "& Send E-Mails";
      }

      return buttonText;
    },
  },
  methods: {
    getEvents() {
      HTTP.get("events").then((result) => {
        this.events = result.data.rows;
      });
    },
    getInvestorsAndFirms: function () {
      this.investors = [];
      let uri = "firms";

      HTTP.get(uri)
        .then((result) => {
          this.firms = result.data.rows;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting firm data",
            showConfirmButton: false,
            timer: 1500,
          });
        });

      uri = "firms?isInvestor=true";
      HTTP.get(uri)
        .then((result) => {
          this.investors = [];

          if (result.data.rows) {
            result.data.rows.forEach((row) => {
              row.users.forEach((user) => {
                this.investors.push({
                  id: user.id,
                  name: user.name,
                  fon: row.name,
                  email: user.email,
                  phone: user.tel,
                });
              });
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting investors data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    addNewOption(index) {
      this.newSurvey.questions[index].options.push("");
    },
    deleteOption(index, optionIndex) {
      this.newSurvey.questions[index].options.splice(optionIndex, 1);
    },
    addNewQuestion() {
      this.newSurvey.questions.push({
        type: 1, // It's means multi option or rich answers
        question: null,
        options: [],
      });
    },
    setSelectedCount() {
      this.selectedInvestorsCount = this.$refs["investorLists"]
        ? this.$refs["investorLists"].selectedRows.length
        : 0;
    },
    createSurvey() {
      this.newSurvey.selectedInvestors = JSON.stringify(
        this.$refs["investorLists"].selectedRows
      );
      if (this.$route.name == "survey-edit") {
        this.updateSurvey();
      } else {
        HTTP.post("surveys", this.newSurvey)
          .then(() => {
            router.push({ name: "surveys" });
          })
          .catch(() => {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Error while creating your data",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },
    updateSurvey() {
      HTTP.put("surveys/" + this.$route.params.id, this.newSurvey)
        .then(() => {
          router.push({ name: "surveys" });
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while creating your data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    getSurveyDetail() {
      HTTP.get("surveys/" + this.$route.params.id).then((result) => {
        this.newSurvey = {
          name: result.data[0].name,
          desc: result.data[0].desc,
          eventId: result.data[0].eventId,
          questions: JSON.parse(result.data[0].questions),
        };
      });
    },
  },
};
</script>